var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", [
    !_vm.Connected
      ? _c(
          "div",
          [
            _c(
              "v-btn",
              {
                staticClass: "white--text",
                attrs: { color: "red lighten-2", disabled: _vm.isConnecting },
                on: { click: _vm.ConnectToIntifaceDesktop }
              },
              [_vm._v("\n      Connect To Intiface Desktop\n    ")]
            ),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                staticClass: "white--text",
                attrs: {
                  color: "red lighten-2",
                  disabled: !_vm.HasWebBluetooth
                },
                on: { click: _vm.ConnectInBrowser }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.HasWebBluetooth
                        ? "Connect In Browser"
                        : "Requires WebBluetooth"
                    ) +
                    "\n    "
                )
              ]
            ),
            _vm._v(" "),
            _vm.uiMessage
              ? _c(
                  "v-card",
                  { staticClass: "message-card", attrs: { elevation: "3" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { shrink: "" } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: { click: _vm.CloseUiMessage }
                              },
                              [_c("v-icon", [_vm._v("close")])],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-flex", { staticClass: "message-flex" }, [
                          _vm.uiMessage && _vm.uiMessage[0] === 0
                            ? _c("span", { staticClass: "error-text" }, [
                                _vm._v("Error:")
                              ])
                            : _vm._e(),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.uiMessage[1]) +
                              "\n        "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isConnecting
              ? _c(
                  "v-card",
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { shrink: "" } },
                          [
                            _c("v-progress-circular", {
                              attrs: { indeterminate: "", color: "purple" }
                            }),
                            _c("span", [
                              _vm._v("Trying to connect to Intiface Desktop...")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isConnecting
              ? _c(
                  "v-expansion-panel",
                  [
                    _c(
                      "v-expansion-panel-content",
                      {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function() {
                                return [
                                  _vm._v(
                                    "\n          Advanced Settings\n        "
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3555544345
                        )
                      },
                      [
                        _vm._v(" "),
                        _c(
                          "v-container",
                          [
                            _c("v-checkbox", {
                              attrs: { label: "Start Scanning On Connect" },
                              model: {
                                value: _vm.scanOnConnect,
                                callback: function($$v) {
                                  _vm.scanOnConnect = $$v
                                },
                                expression: "scanOnConnect"
                              }
                            }),
                            _vm._v(" "),
                            _c("v-divider"),
                            _vm._v(" "),
                            _c("v-subheader", [
                              _vm._v(
                                "\n            Websocket Addresses\n          "
                              )
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.desktopAddresses, function(address) {
                              return _c(
                                "v-layout",
                                {
                                  key: address.Id,
                                  class: address.IsValidURL
                                    ? "address-line-correct address-line"
                                    : "address-line-incorrect address-line"
                                },
                                [
                                  _c(
                                    "v-flex",
                                    { staticClass: "address-entry" },
                                    [
                                      _c("v-text-field", {
                                        attrs: { label: "Host" },
                                        on: {
                                          change: function($event) {
                                            return _vm.StoreAddressCookie()
                                          }
                                        },
                                        model: {
                                          value: address.Host,
                                          callback: function($$v) {
                                            _vm.$set(address, "Host", $$v)
                                          },
                                          expression: "address.Host"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { staticClass: "address-entry" },
                                    [
                                      _c("v-text-field", {
                                        attrs: { label: "Port", mask: "#####" },
                                        on: {
                                          change: function($event) {
                                            return _vm.StoreAddressCookie()
                                          }
                                        },
                                        model: {
                                          value: address.Port,
                                          callback: function($$v) {
                                            _vm.$set(address, "Port", $$v)
                                          },
                                          expression: "address.Port"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    [
                                      _c("v-checkbox", {
                                        attrs: { label: "Insecure" },
                                        on: {
                                          change: function($event) {
                                            return _vm.StoreAddressCookie()
                                          }
                                        },
                                        model: {
                                          value: address.Insecure,
                                          callback: function($$v) {
                                            _vm.$set(address, "Insecure", $$v)
                                          },
                                          expression: "address.Insecure"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    [
                                      _c("v-checkbox", {
                                        attrs: { label: "Secure" },
                                        on: {
                                          change: function($event) {
                                            return _vm.StoreAddressCookie()
                                          }
                                        },
                                        model: {
                                          value: address.Secure,
                                          callback: function($$v) {
                                            _vm.$set(address, "Secure", $$v)
                                          },
                                          expression: "address.Secure"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { shrink: "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.RemoveAddress(
                                                address.Id
                                              )
                                            }
                                          }
                                        },
                                        [_c("v-icon", [_vm._v("close")])],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.AddAddress()
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("add")])],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.ResetAddresses()
                                  }
                                }
                              },
                              [_vm._v("\n            Reset\n          ")]
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.Connected
      ? _c(
          "div",
          [
            _c(
              "v-btn",
              {
                attrs: { color: "red lighten-2", dark: "" },
                on: { click: _vm.Disconnect }
              },
              [_vm._v("\n      Disconnect\n    ")]
            ),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                attrs: { color: "red lighten-2", dark: "" },
                on: { click: _vm.ToggleScanning }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.isScanning ? "Stop Scanning" : "Start Scanning"
                    ) +
                    "\n    "
                )
              ]
            ),
            _vm._v(" "),
            _c("v-subheader", [_vm._v("\n      Device List\n    ")]),
            _vm._v(" "),
            _vm.clientDevices.length === 0
              ? _c("div", [_vm._v("No Devices Available")])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.clientDevices, function(device) {
              return _c("v-checkbox", {
                key: device.Index,
                attrs: { value: device.Index, label: device.Name },
                on: { change: _vm.FireChange },
                model: {
                  value: _vm.selectedDevices,
                  callback: function($$v) {
                    _vm.selectedDevices = $$v
                  },
                  expression: "selectedDevices"
                }
              })
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }