var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "" } },
        [
          _c("header", [
            _c(
              "div",
              { ref: "patreonButton", attrs: { id: "patreon-button" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "_2KV-widgets-shared--patreonWidgetWrapper",
                    attrs: { "data-reactroot": "" }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "sc-bxivhb ffInCX",
                        attrs: {
                          color: "primary",
                          type: "button",
                          href:
                            "https://www.patreon.com/bePatron?u=2860444&redirect_uri=http%3A%2F%2Fbuttplug.world%2Ftest.html&utm_medium=widget",
                          role: "button"
                        }
                      },
                      [
                        _c("div", { staticClass: "sc-htpNat gdWQYu" }, [
                          _c(
                            "div",
                            {
                              staticClass: "sc-gzVnrw dJCpyC",
                              attrs: {
                                display: "flex",
                                wrap: "nowrap",
                                direction: "[object Object]"
                              }
                            },
                            [
                              _c("div", { staticClass: "sc-dnqmqq llsQFn" }, [
                                _c(
                                  "span",
                                  { staticClass: "sc-htoDjs fqfmvk" },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          viewBox: "0 0 569 546",
                                          version: "1.1",
                                          xmlns: "http://www.w3.org/2000/svg"
                                        }
                                      },
                                      [
                                        _c("title", [_vm._v("Patreon logo")]),
                                        _vm._v(" "),
                                        _c("g", [
                                          _c("circle", {
                                            attrs: {
                                              "data-color": "1",
                                              id: "Oval",
                                              cx: "362.589996",
                                              cy: "204.589996",
                                              r: "204.589996"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("rect", {
                                            attrs: {
                                              "data-color": "2",
                                              id: "Rectangle",
                                              x: "0",
                                              y: "0",
                                              width: "100",
                                              height: "545.799988"
                                            }
                                          })
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "sc-gqjmRU fFOxVX",
                                attrs: { width: "1.5" }
                              }),
                              _vm._v(
                                "\n                Give us money\n              "
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  ]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "v-layout",
            {
              attrs: {
                id: "playground-container",
                column: "",
                "align-center": "",
                "justify-top": ""
              }
            },
            [
              _c(
                "v-flex",
                { staticClass: "settings", attrs: { shrink: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "purple darken-2", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.ToggleDialog()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { dark: "", left: "" } }, [
                        _vm._v("settings")
                      ]),
                      _vm._v("Menu\n        ")
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "top-divider" }),
              _vm._v(" "),
              !this.client
                ? _c("v-flex", [_c("h1", [_vm._v("Not Connected.")])])
                : _vm._e(),
              _vm._v(" "),
              this.client && this.client.connected && this.devices.length === 0
                ? _c("v-flex", [_c("h1", [_vm._v("No Devices Activated.")])])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(this.devices, function(device) {
                return _c(
                  "v-flex",
                  { key: device.index, attrs: { shrink: "" } },
                  [
                    _vm.canLinear(device)
                      ? _c("position-component", {
                          key: device.index + "LinearAll",
                          attrs: { device: device },
                          on: {
                            dragstart: _vm.OnDragStart,
                            dragstop: _vm.OnDragStop
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canVibrate(device)
                      ? _c("vibration-component", {
                          key: device.index + "-VibrateAll",
                          attrs: { device: device },
                          on: {
                            dragstart: _vm.OnDragStart,
                            dragstop: _vm.OnDragStop
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.numVibrators(device) > 1
                      ? _c(
                          "div",
                          _vm._l(_vm.numVibrators(device), function(
                            vibratorIndex
                          ) {
                            return _c("vibration-component", {
                              key: device.index + "-Vibrate" + vibratorIndex,
                              attrs: {
                                device: device,
                                vibratorIndex: vibratorIndex - 1
                              },
                              on: {
                                dragstart: _vm.OnDragStart,
                                dragstop: _vm.OnDragStop
                              }
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canRotate(device)
                      ? _c("rotation-component", {
                          key: device.index + "RotateAll",
                          attrs: { device: device },
                          on: {
                            dragstart: _vm.OnDragStart,
                            dragstop: _vm.OnDragStop
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: {
                "content-class": _vm.$vuetify.breakpoint.smAndDown
                  ? "main-dialog"
                  : "main-dialog tab-panel",
                fullscreen: _vm.$vuetify.breakpoint.smAndDown,
                "hide-overlay": _vm.$vuetify.breakpoint.smAndDown,
                "max-width": "60%"
              },
              model: {
                value: _vm.menuOpened,
                callback: function($$v) {
                  _vm.menuOpened = $$v
                },
                expression: "menuOpened"
              }
            },
            [
              _vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "v-toolbar",
                    { attrs: { dark: "", color: "purple darken-2" } },
                    [
                      _c(
                        "v-toolbar-side-icon",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: { click: _vm.ToggleDialog }
                            },
                            [_c("v-icon", [_vm._v("close")])],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-toolbar-title", [_vm._v("Buttplug Playground")])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c(
                    "v-tabs",
                    { staticClass: "fixed-tabs-bar" },
                    [
                      _c("v-tab", { attrs: { href: "#intifacepanel" } }, [
                        _vm._v(" Intiface ")
                      ]),
                      _vm._v(" "),
                      _c("v-tab", { attrs: { href: "#helppanel" } }, [
                        _vm._v(" Help ")
                      ]),
                      _vm._v(" "),
                      _c("v-tab", { attrs: { href: "#aboutpanel" } }, [
                        _vm._v(" About ")
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "intifacepanel" } },
                        [
                          _c("buttplug-panel", {
                            attrs: { client: _vm.client },
                            on: {
                              selecteddeviceschange: _vm.OnSelectedDevicesChange
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "aboutpanel" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "about-card", attrs: { flat: "" } },
                            [
                              _c("p", [
                                _c("b", [_vm._v("Buttplug Playground")])
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "\n                Version:\n                "
                                ),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "https://github.com/metafetish/buttplug-playground/tree/" +
                                        _vm.config.build_commit
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.config.build_commit))]
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "Updated: " + _vm._s(_vm.config.build_date)
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "Buttplug v" +
                                    _vm._s(_vm.config.buttplug_version)
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "Component v" +
                                    _vm._s(_vm.config.component_version)
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v("\n                Developed By "),
                                _c(
                                  "a",
                                  { attrs: { href: "https://metafetish.com" } },
                                  [_vm._v("Metafetish")]
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "\n                Open Source!\n                "
                                ),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "https://github.com/metafetish/buttplug-playground"
                                    }
                                  },
                                  [_vm._v("Code available on Github")]
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "\n                We Like Money!\n                "
                                ),
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "https://patreon.com/qdot" }
                                  },
                                  [_vm._v("Visit Our Patreon")]
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-tab-item", {
                        staticClass: "help-panel",
                        attrs: { value: "helppanel" },
                        domProps: { innerHTML: _vm._s(_vm.helpText) }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }