var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rotation" },
    [
      _c("h2", [_vm._v(_vm._s(_vm.device.Name) + " - Rotation Control")]),
      _vm._v(" "),
      _c("vue-slider", {
        ref: "slider",
        attrs: { "real-time": true, min: -100, max: 100 },
        on: {
          "drag-start": _vm.OnDragStart,
          "drag-end": _vm.OnDragEnd,
          callback: _vm.OnValueChanged,
          click: _vm.OnValueChanged
        },
        model: {
          value: _vm.sliderValue,
          callback: function($$v) {
            _vm.sliderValue = $$v
          },
          expression: "sliderValue"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }