var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "position-control" },
    [
      _c("h2", [_vm._v(_vm._s(_vm.device.Name) + " - Position Control")]),
      _vm._v(" "),
      _c("vue-slider", {
        ref: "positionSlider",
        attrs: { "real-time": true, speed: 0.1 },
        on: {
          "drag-start": _vm.OnDragStart,
          "drag-end": _vm.OnDragEnd,
          callback: _vm.OnPositionValueChanged
        },
        model: {
          value: _vm.positionValue,
          callback: function($$v) {
            _vm.positionValue = $$v
          },
          expression: "positionValue"
        }
      }),
      _vm._v(" "),
      _c("vue-slider", {
        ref: "timeSlider",
        attrs: {
          "real-time": true,
          min: 0.1,
          max: 3,
          interval: 0.05,
          speed: 0.1
        },
        on: {
          "drag-start": _vm.OnDragStart,
          "drag-end": _vm.OnDragEnd,
          callback: _vm.OnTimeValueChanged
        },
        model: {
          value: _vm.timeValue,
          callback: function($$v) {
            _vm.timeValue = $$v
          },
          expression: "timeValue"
        }
      }),
      _vm._v(" "),
      !_vm.isOscillating
        ? _c(
            "v-btn",
            { ref: "oscillate", on: { click: _vm.OnOscillateClick } },
            [_vm._v("Start Oscillation")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isOscillating
        ? _c(
            "v-btn",
            { ref: "oscillate", on: { click: _vm.OnOscillateClick } },
            [_vm._v("Stop Oscillation")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }